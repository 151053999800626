<template>
  <div class="contacts">
    <h1 class="contacts__title">{{ $t('contacts') }}</h1>
    <div class="contacts__content">
      <div class="contacts__content-col">
        <div class="content-item address">
          <h2>{{ $t('address') }}</h2>
          <p v-html="$t('address-first')"></p>
          <p>{{ $t('address-second') }}</p>
        </div>
        <div class="content-item legal-info">
          <h2>{{ $t('legal-information') }}</h2>
          <p v-html="$t('address-third')"></p>
        </div>
      </div>
      <div class="contacts__content-col">
        <div class="content-item mail">
          <h2>{{ $t('mail') }}</h2>
          <a target="_blank" href="mailto:info@climatesecurity.org">info@climatesecurity.org</a>
        </div>
        <div class="content-item website">
          <h2>{{ $t('website') }}</h2>
          <a target="_blank" href="https://xn--j1agca3a5c.xn--p1ai/ru/ecophone">https://экофон.рф/ru/ecophone</a>
        </div>
        <div class="content-item social">
          <h2>{{ $t('social-media') }}</h2>
          <div class="content-item__social">
            <a href=" https://vk.com/ecophone_fund" target="_blank">
              <img src="@/assets/svg/icon-vk.svg" alt="">
            </a>
            <a href="https://t.me/ecofon_news" target="_blank">
              <img src="@/assets/svg/icon-telegram.svg" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts"
}
</script>

<style lang="scss" scoped>
.contacts {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 150px 163px 50px 50px;
  background: #F5F5F5;
  min-height: 100vh;

  @media (max-width: 1050px) {
    padding: 150px 50px 50px 50px;
  }
  @media (max-width: 970px) {
    flex-direction: column;
    gap: 30px;
  }
  @media (max-width: 768px) {
    padding: 104px 20px 50px 20px;
  }
  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
  }

  &__content {
    display: flex;
    gap: 40px;
    padding: 40px 44px 72px 40px;
    background: #FFFFFF;
    border-radius: 20px;
    max-height: 334px;

    @media (max-width: 590px) {
      flex-direction: column;
      gap: 9px;
      padding: 30px 12px 55px 12px;
      max-height: 517px;
    }
    &-col {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .content-item {

      a {
        &:hover {
          text-decoration: underline;
        }
      }

      h2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #9A9A9A;
        margin-bottom: 5px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #343432;
      }

      &__social {
        display: flex;
        gap: 20px;
        margin-top: 10px;

        img {
          width: 48px;
          height: 48px;
        }
      }
    }

    .legal-info {

      p {
        max-width: 366px;
      }
    }
    .website {

      a {
        max-width: 270px;
        word-break: break-word;
      }
    }
  }
}
</style>
